import logo from "../assets/logo.png";
import Cookies from "universal-cookie";
import { useState } from "react";
import "../App.css";
import { Link } from "react-router-dom";
import { logout } from "../apis/auth";

const cookies = new Cookies();

function Home() {
  const now = new Date().toISOString().slice(0, 10);
  const lectures = cookies.get("lectures");
  const [date, setDate] = useState(now);
  const [stage, setStage] = useState("1");
  const [group, setGroup] = useState("A");
  const [time, setTime] = useState("08:30");
  const [lecture, setLecture] = useState(lectures[0].course);

  function logMeOut() {
    logout();
    window.location.reload(true);
  }

  return (
    <div className="text-center p-3">
      <div className="bg-dark py-3 text-end w-100">
        <a className="text-white" onClick={logMeOut}>
          LogOut
        </a>
      </div>
      <img src={logo} alt="" width={150} />
      <br />
      <label htmlFor="">Select date : {date}</label>
      <br />
      <input
        type="date"
        defaultValue={now}
        onChange={(e) => setDate(e.target.value)}
      />
      <br />
      <hr />

      <select
        onChange={(e) => setTime(e.target.value)}
        className="form-select form-select-sm mt-2"
        aria-label=".form-select-sm example"
      >
        <option value="08:30">8:30</option>
        <option value="09:25">9:25</option>
        <option value="10:25">10:25</option>
        <option value="11:20">11:20</option>
        <option value="12:15">12:15</option>
      </select>
      <hr />
      <select
        onChange={(e) => setLecture(e.target.value)}
        className="form-select form-select-sm mt-2"
        aria-label=".form-select-sm example"
      >
        {lectures.map((lecture) => {
          return (
            <option key={lecture.id} value={lecture.course}>
              {lecture.course}
            </option>
          );
        })}
      </select>

      <hr />
      <select
        onChange={(e) => setStage(e.target.value)}
        className="form-select form-select-sm mt-2"
        aria-label=".form-select-sm example"
      >
        <option value="1">ئێک</option>
        <option value="2">دوو</option>
        <option value="3">سێ</option>
        <option value="4">چار</option>
        <option value="5">پێنج</option>
      </select>

      <hr />

      <select
        onChange={(e) => setGroup(e.target.value)}
        className="form-select form-select-sm mt-2"
        aria-label=".form-select-sm example"
      >
        <option value="A">A</option>
        <option value="B">B</option>
      </select>

      <hr />
      <Link to={`class/${date}/${time}/${lecture}/${stage}/${group}`}>
        <input
          className="bg-primary border-0 py-1 px-3 text-white "
          type="button"
          value="Enter Class"
        />
      </Link>
    </div>
  );
}

export default Home;
