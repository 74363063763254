import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const url = "https://students.viya-group.net/api/";

export function logout() {
  cookies.remove("isLogined");
  cookies.remove("fullname");
  cookies.remove("lectures");
}

export async function addStudents(date, time, stage, group, lecture, students) {
  let newStage;
  switch (stage) {
    case "1":
      newStage = "ئێک";
      break;
    case "2":
      newStage = "دوو";
      break;
    case "3":
      newStage = "سێ";
      break;
    case "4":
      newStage = "چار";
      break;
    default:
      newStage = "پێنج";
      break;
  }
  const path = `${url}add`;
  const teacher = cookies.get("fullname");

  await Promise.all(
    students.map((student) => {
      const sentData = {
        name: student,
        stage: newStage,
        class: group,
        teacher: teacher,
        course: lecture,
        date: date,
        time: time,
      };
      axios.post(path, sentData).then((e) => console.log(e));
    })
  );
}

export async function login(data) {
  let result = await axios.post(url + "login", data);
  return result.data;
}

export async function getStudents(stage, group) {
  const path = `${url}students/${stage}/${group}`;
  let result = await axios.get(path);
  return result.data;
}
