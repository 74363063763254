import logo from "../assets/logo.png";
import { useState } from "react";
import Cookies from "universal-cookie";
import { login } from "../apis/auth";

const cookies = new Cookies();

function Auth() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setError] = useState("false");

  const timestamp = new Date().getTime();
  const expire = timestamp + 60 * 60 * 24 * 1000 * 365;
  const expireDate = new Date(expire);

  async function loginAction(e) {
    e.preventDefault();
    let loginData = { username: username, password: password };
    let userData = await login(loginData);
    if (userData["message"] == "succed") {
      cookies.set("isLogined", true, {
        expires: expireDate,
      });
      cookies.set("fullname", userData["fullname"], {
        expires: expireDate,
      });
      cookies.set("lectures", userData["lectures"], {
        expires: expireDate,
      });
      setError(false);
      window.location.reload(true);
    } else {
      setError(true);
    }
  }

  return (
    <div className="text-center">
      <img src={logo} alt="logo" width={200} />
      <form onSubmit={loginAction}>
        <div className="form-group">
          <input
            required
            type="text"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Enter email"
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
          <small id="emailHelp" className="form-text text-muted">
            We'll never share your email with anyone else.
          </small>
        </div>
        <div className="form-group">
          <input
            required
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Login
        </button>
      </form>
    </div>
  );
}

export default Auth;
