import React from 'react';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import CheckAuth from './CheckAuth';
import "./App.css";
import Class from './views/Class';

function App() {
  return (
    <div className="h-100 main-width mx-auto ">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<CheckAuth />}></Route>
          <Route path="/class/:date/:time/:lecture/:stage/:group" element={<Class />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App;
