import Cookies from 'universal-cookie';

import Auth from './views/Auth';
import Home from './views/Home';

const cookies = new Cookies()
let isLogined = cookies.get('isLogined')

function CheckAuth() {

  return (
    isLogined!=null && isLogined ?
    
    <div className='h-100'><Home/></div>
    :
    <div className='h-100'><Auth/></div>
  );
}

export default CheckAuth