import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getStudents, addStudents } from "../apis/auth";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import "../App.css";

function Class() {
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [absStudents, setAbsenc] = useState([]);
  const { date, time, lecture, stage, group } = useParams();

  async function add() {
    const result = await addStudents(
      date,
      time,
      stage,
      group,
      lecture,
      absStudents
    );
    navigate("/");
  }

  useEffect(() => {
    async function fetchData() {
      const data = await getStudents(stage, group);
      setStudents(data);
    }
    fetchData();
  }, []);

  function checkStudent(event) {
    const { value, checked } = event.target;

    if (checked) {
      setAbsenc((pre) => [...pre, value]);
    } else {
      setAbsenc((pre) => {
        return [...pre.filter((name) => name != value)];
      });
    }
  }
  return (
    <div className=" px-5 pb-5" id="class-id">
      <div className="row justify-content-between  py-2 font-weight-bold text-white ">
        {stage} {group} Students
        <Link to={"/"}>
          <p className="text-white">Home</p>
        </Link>
      </div>
      {students.map((student) => {
        return (
          <div
            id="name"
            className="my-2 py-2 px-3 row justify-content-between rounded"
            key={student.id}
          >
            <input
              type="checkbox"
              value={student.name}
              onChange={checkStudent}
            />
            {student.name}
          </div>
        );
      })}
      <div className="text-center">
        <input onClick={add} className="px-5" type="button" value="Add" />
      </div>
      <div className="text-white"></div>
    </div>
  );
}

export default Class;
